import * as React from 'react';
import { Vehicle } from '../../../types/Vehicle';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Grid, Typography } from '@mui/material';
import { config } from '../../../config';
import { ListingType } from '../../../enum/vehicleListing.enum';
import { PopupManager } from '../../../util/popupManager';

interface Props {
    v: Vehicle;
    newUserId: number;
    onUpdate: (v: Vehicle) => void;
    onEdit: (v: Vehicle) => void;
    onChangeOwnership: (v: Vehicle, userId: number) => void;
    onDelete: (v: Vehicle) => void;
    onChangeListing: (v: Vehicle, isSell: boolean) => void;
}
export const VehicleActions: React.FC<Props> = ({ v, newUserId, onEdit, onChangeOwnership, onDelete, onChangeListing, onUpdate }) => {

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [openPhoneInput, setOpenPhoneInput] = React.useState(false);

    const handleClose = (event: Event) => {
        if (anchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleEdit = () => {
        onEdit(v);
    };

    const handleRemove = () => {
        onChangeOwnership(v, 1);
    };

    const handleDelete = () => {
        onDelete(v);
    };

    const handleRestore = () => {
        onChangeOwnership(v, newUserId);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleAddMoreUsers = () => {
        if (v.users.length === 2) {
            PopupManager.showErrorToast('You cannot add more than 2 other users to the vehicle. Please remove exiting vehicle if you want to add more users.')
        } else {
            PopupManager.showConfirmBox('Allow more users to add records for this vehicle', 'In future this user also can add records for this vehicle', 'phone').then(res => {
                if (res.confirmed) {
                    const { phone, country } = res.data;
                    console.log(phone, country);
                    const next = { ...v };
                    next.users = [...next.users, phone];
                    onUpdate(next);
                }
            });
        }
    }

    const handleRemoveUser = (usr: string) => {
        PopupManager.showConfirmBox(`Are you sure you need to remove ${usr} from this vehicle`, 'In future this user cannot add records for this vehicle', 'boolean').then(res => {
            if (res.confirmed) {
                const next = { ...v };
                next.users = next.users.filter(u => u !== usr);
                onUpdate(next);
            }
        });
    }

    return (
        <Grid container justifyContent="end" margin={1}>
            <React.Fragment>
                <ButtonGroup
                    variant="text"
                    ref={anchorRef}
                >
                    {v.UserId === newUserId && <Button size='small' variant='text' onClick={handleEdit} >Edit Vehicle</Button>}
                    {v.UserId === config.adminUserId ? <Button size='small' variant='text' onClick={() => handleRestore()}>Add to my own vehicles(Change ownership)</Button> :
                        v.UserId === newUserId ? <>
                            <Button size='small' variant='text' onClick={handleRemove}>Remove from profile</Button>
                            <Button
                                variant='text'
                                size="small"
                                onClick={handleToggle}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        </> : <></>}
                </ButtonGroup>
                <Popper
                    sx={{ zIndex: 1 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {
                                            v.listing === ListingType.ListingForMyOwn ?
                                                <MenuItem
                                                    onClick={() => onChangeListing(v, true)}
                                                >
                                                    Sell this vehicle
                                                </MenuItem> :
                                                <MenuItem
                                                    onClick={() => onChangeListing(v, false)}
                                                >
                                                    Add to my own vehicles(Stop sale)
                                                </MenuItem>
                                        }

                                        <MenuItem
                                            onClick={handleDelete}
                                        >
                                            <Typography color="red">Delete this vehicle from OdoCert </Typography>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => handleAddMoreUsers()}
                                        >
                                            <Typography variant='subtitle2' color="purple">Add more users to this vehicle</Typography>
                                        </MenuItem>
                                        {v.users.map(usr => <MenuItem
                                            key={usr}
                                            onClick={() => handleRemoveUser(usr)}
                                        >
                                            <Typography variant='subtitle2' color="purple">Remove additional user {usr}</Typography>
                                        </MenuItem>)}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </React.Fragment>
        </Grid>
    );
}
