export class StorageService {
    public static setItem(key: string, val: string) {
        localStorage.setItem(key, val);
    }

    public static getItem(key: string) {
        return localStorage.getItem(key);
    }

    public static setNetworkItem(key: string, val: string) {
        localStorage.setItem(`network-${key}`, val);
    }

    public static getNetworkItem(key: string) {
        return localStorage.getItem(`network-${key}`);
    }

    public static getAllNetworkKeys() {
        return Object.keys(localStorage).filter(key => key.startsWith('network-'));
    }

    public static clearItem = (key: string) => {
        return localStorage.removeItem(key);
    }

    public static clearAll = (exceptArr?: string[]) => {
        localStorage.clear();
        // Object.keys(localStorage).forEach(lKey => {
        //     const prefix = lKey.split('stamp')[0];
        //     if (exceptArr.)
        // });
    }
}