import { RecordVisibility } from "../enum/recordVisibility.enum"
import { PartsFilterType } from "../types/filters/PartsFilterType"
import { RecordsFilterType } from "../types/filters/RecordsFilterType"
import { VehicleFilterType } from "../types/filters/VehicleFilterType"

export const NULL_LOCATION = {
    LAT: -1000,
    LNG: -1000
}

export const hiddenFilterParams = ['UserId', 'listing'];

export const RECORDS_FILTER_DEFAULT_VALUES: RecordsFilterType = {
    visibility: RecordVisibility.public,
    categories: [],
    chassis: '',
    regNo: '',

    VehicleId: undefined,
    UserId: undefined,

    odo: undefined, 
    dateMin: undefined,
    dateMax: undefined
}

export const PARTS_FILTER_DEFAULT_VALUES: PartsFilterType = {
    // yearMin: undefined,
    // yearMax: undefined,
    year: undefined,

    priceMin: undefined,
    priceMax: undefined,
    
    vehicleType: -1,
    category: '',
    brand: -1, // manufac
    model: -1,

    // location cordinates
    country: 'LK',
    lat: undefined,
    lng: undefined,
    province: undefined,
    district: undefined,
    area: undefined,

    UserId: undefined
}

export const VEHICLE_FILTER_DEFAULT_VALUES: VehicleFilterType = {

    key: undefined,

    yearMin: undefined,
    yearMax: undefined,

    priceMin: undefined,
    priceMax: undefined,

    brand: -1,   // manufac
    model: '',

    odoMin: undefined,  // odo
    odoMax: undefined,

    type: undefined,

    fuel: undefined,

    // cc: number; TODO: enable in future
    gear: undefined,

    // location cordinates
    country: 'LK',
    lat: undefined,
    lng: undefined,
    province: undefined,
    district: undefined,
    area: undefined,

    UserId: undefined,
    users: undefined


}

export const RANDOM_COLORS: Array<"primary" | "default" | "secondary" | "error" | "info" | "success" | "warning"> = [
    // 'error', 'info', 'warning', 'success', 'primary', 'secondary', 'tertiary'
    "primary", "default", "secondary", "error", "info", "success", "warning"
]