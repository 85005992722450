import { useSearchVehiclesQuery } from "../state/api/vehicle.api";
import { OwnerTypes } from "../enum/ownerTypes.enum";
import { useUser } from "./useUser";
import { Vehicle } from "../types/Vehicle";

export const useOwnVehicles = () => {
  const { user } = useUser();
  const { data } = useSearchVehiclesQuery({
    limit: undefined,
    offset: 0,
    keywords: '',
    filter: {
      UserId: user.id
    },
    ownerType: OwnerTypes.My
  });
  const ownVehicles = (data ?? []) as Vehicle[];

  const isOwnVehicle = (vId: number | string | undefined) => {
    return ownVehicles.find(v => v.id === Number(vId)) !== null;
  }
  return { ownVehicles, isOwnVehicle };
};
