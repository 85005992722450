import { LocalStorageKeys } from "../enum/localStorageKeys.enum";

export const setLocalStorage = (key: LocalStorageKeys, value: any) => {
    localStorage.setItem(key, value);
}

export const setLocalStorageAny = (key: string, value: any) => {
    localStorage.setItem(key, value);
}

export const getLocalStorage = (key: LocalStorageKeys, defaultValue?: any) => {
    const val = localStorage.getItem(key);
    if (val === undefined || val === 'undefined' || val === null || val === 'null' || val === '') {
        return defaultValue;
    }
    return val;
}

export const getLocalStorageAny = (key: string) => {
    return localStorage.getItem(key);
}

// export const clearLocalStorage = () => {
//     return localStorage.clear();
// }


export const getLocalStorageNumber = (key: LocalStorageKeys) => {
    const val = localStorage.getItem(key);
    const num = Number(val);
    return isNaN(num) ? 0 : num;
}