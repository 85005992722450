import { Grid } from "@mui/material";
import { OwnerTypes } from "../../../enum/ownerTypes.enum";
import { useUser } from "../../../hooks/useUser";
import { useSearchVehiclesQuery } from "../../../state/api/vehicle.api";
import { Vehicle } from "../../../types/Vehicle";
import { VehicleGridTable } from "./vehicleGridTable";
import { useNavigate } from "react-router-dom";

export const UserVehicleViewContainer = () => {
    const user = useUser();
    const navigate = useNavigate();

    const { data, isFetching } = useSearchVehiclesQuery({
        limit: undefined,
        offset: undefined,
        ownerType: OwnerTypes.My,
        keywords: '',
        filter: {
            users: user.user.identifier,
        }
    });

    const handleSelect = (vid: number) => {
        const path = `/vehicle/${vid}`;
        navigate(path);
    };

    return <div>
        <Grid container spacing={1}>
            {
                data?.map((v: Vehicle) => (
                    <VehicleGridTable
                        vehicle={v} key={`${v.id}${v.createdAt.toString()}`}
                        onSelect={() => handleSelect(v.id)} />
                ))
            }
        </Grid>
    </div>
}