import { Country } from "../types/Country";

export const countryList: Country[] = [
  {
    label: "Sri Lanka",
    code: "94",
    id: "LK",

    provinceList: [
      {
        id: 1,
        label: "Central",
        districts: [
          {
            id: 1,
            label: "Kandy",
            areas: []
          },
          {
            id: 2,
            label: "Matale",
            areas: []
          },
          {
            id: 3,
            label: "Nuwara Eliya",
            areas: []
          },
        ]
      },
      {
        id: 2,
        label: "Eastern",
        districts: [
          {
            id: 4,
            label: "Ampara",
            areas: []
          },
          {
            id: 5,
            label: "Batticaloa",
            areas: []
          },
          {
            id: 6,
            label: "Trincomalee",
            areas: []
          }
        ]
      },
      {
        id: 3,
        label: "North Central",
        districts: [
          {
            id: 7,
            label: "Anuradhapura",
            areas: []
          },
          {
            id: 8,
            label: "Polonnaruwa",
            areas: []
          }
        ]
      },
      {
        id: 4,
        label: "Northern",
        districts: [
          {
            id: 9,
            label: "Jaffna",
            areas: []
          },
          {
            id: 10,
            label: "Kilinochchi",
            areas: []
          },
          {
            id: 11,
            label: "Mannar",
            areas: []
          },
          {
            id: 12,
            label: "Mullaitivu",
            areas: []
          },
          {
            id: 13,
            label: "Vavuniya",
            areas: []
          }
        ]
      },
      {
        id: 5,
        label: "North Western",
        districts: [
          {
            id: 14,
            label: "Kurunegala",
            areas: []
          },
          {
            id: 15,
            label: "Puttalam",
            areas: []
          }
        ]
      },
      {
        id: 6,
        label: "Sabaragamuwa",
        districts: [
          {
            id: 16,
            label: "Kegalle",
            areas: []
          },
          {
            id: 17,
            label: "Ratnapura",
            areas: []
          }
        ]
      },
      {
        id: 7,
        label: "Southern",
        districts: [
          {
            id: 18,
            label: "Galle",
            areas: []
          },
          {
            id: 19,
            label: "Hambantota",
            areas: []
          },
          {
            id: 20,
            label: "Matara",
            areas: []
          }
        ]
      },
      {
        id: 8,
        label: "Uva",
        districts: [
          {
            id: 21,
            label: "Badulla",
            areas: []
          },
          {
            id: 22,
            label: "Monaragala",
            areas: []
          }
        ]
      },
      {
        id: 9,
        label: "Western",
        districts: [
          {
            id: 23,
            label: "Colombo",
            areas: []
          },
          {
            id: 24,
            label: "Gampaha",
            areas: []
          },
          {
            id: 25,
            label: "Kaluthara",
            areas: []
          }
        ]
      },
    ]
  },
  {
    label: "Afghanistan",
    code: "93",
    id: "AF",
  },
  {
    label: "Aland Islands",
    code: "358",
    id: "AX",
  },
  {
    label: "Albania",
    code: "355",
    id: "AL",
  },
  {
    label: "Algeria",
    code: "213",
    id: "DZ",
  },
  {
    label: "AmericanSamoa",
    code: "1684",
    id: "AS",
  },
  {
    label: "Andorra",
    code: "376",
    id: "AD",
  },
  {
    label: "Angola",
    code: "244",
    id: "AO",
  },
  {
    label: "Anguilla",
    code: "1264",
    id: "AI",
  },
  {
    label: "Antarctica",
    code: "672",
    id: "AQ",
  },
  {
    label: "Antigua and Barbuda",
    code: "1268",
    id: "AG",
  },
  {
    label: "Argentina",
    code: "54",
    id: "AR",
  },
  {
    label: "Armenia",
    code: "374",
    id: "AM",
  },
  {
    label: "Aruba",
    code: "297",
    id: "AW",
  },
  {
    label: "Australia",
    code: "61",
    id: "AU",
  },
  {
    label: "Austria",
    code: "43",
    id: "AT",
  },
  {
    label: "Azerbaijan",
    code: "994",
    id: "AZ",
  },
  {
    label: "Bahamas",
    code: "1242",
    id: "BS",
  },
  {
    label: "Bahrain",
    code: "973",
    id: "BH",
  },
  {
    label: "Bangladesh",
    code: "880",
    id: "BD",
  },
  {
    label: "Barbados",
    code: "1246",
    id: "BB",
  },
  {
    label: "Belarus",
    code: "375",
    id: "BY",
  },
  {
    label: "Belgium",
    code: "32",
    id: "BE",
  },
  {
    label: "Belize",
    code: "501",
    id: "BZ",
  },
  {
    label: "Benin",
    code: "229",
    id: "BJ",
  },
  {
    label: "Bermuda",
    code: "1441",
    id: "BM",
  },
  {
    label: "Bhutan",
    code: "975",
    id: "BT",
  },
  {
    label: "Bolivia, Plurinational State of",
    code: "591",
    id: "BO",
  },
  {
    label: "Bosnia and Herzegovina",
    code: "387",
    id: "BA",
  },
  {
    label: "Botswana",
    code: "267",
    id: "BW",
  },
  {
    label: "Brazil",
    code: "55",
    id: "BR",
  },
  {
    label: "British Indian Ocean Territory",
    code: "246",
    id: "IO",
  },
  {
    label: "Brunei Darussalam",
    code: "673",
    id: "BN",
  },
  {
    label: "Bulgaria",
    code: "359",
    id: "BG",
  },
  {
    label: "Burkina Faso",
    code: "226",
    id: "BF",
  },
  {
    label: "Burundi",
    code: "257",
    id: "BI",
  },
  {
    label: "Cambodia",
    code: "855",
    id: "KH",
  },
  {
    label: "Cameroon",
    code: "237",
    id: "CM",
  },
  {
    label: "Canada",
    code: "1",
    id: "CA",
  },
  {
    label: "Cape Verde",
    code: "238",
    id: "CV",
  },
  {
    label: "Cayman Islands",
    code: " 345",
    id: "KY",
  },
  {
    label: "Central African Republic",
    code: "236",
    id: "CF",
  },
  {
    label: "Chad",
    code: "235",
    id: "TD",
  },
  {
    label: "Chile",
    code: "56",
    id: "CL",
  },
  {
    label: "China",
    code: "86",
    id: "CN",
  },
  {
    label: "Christmas Island",
    code: "61",
    id: "CX",
  },
  {
    label: "Cocos (Keeling) Islands",
    code: "61",
    id: "CC",
  },
  {
    label: "Colombia",
    code: "57",
    id: "CO",
  },
  {
    label: "Comoros",
    code: "269",
    id: "KM",
  },
  {
    label: "Congo",
    code: "242",
    id: "CG",
  },
  {
    label: "Congo, The Democratic Republic of the Congo",
    code: "243",
    id: "CD",
  },
  {
    label: "Cook Islands",
    code: "682",
    id: "CK",
  },
  {
    label: "Costa Rica",
    code: "506",
    id: "CR",
  },
  {
    label: "Cote d'Ivoire",
    code: "225",
    id: "CI",
  },
  {
    label: "Croatia",
    code: "385",
    id: "HR",
  },
  {
    label: "Cuba",
    code: "53",
    id: "CU",
  },
  {
    label: "Cyprus",
    code: "357",
    id: "CY",
  },
  {
    label: "Czech Republic",
    code: "420",
    id: "CZ",
  },
  {
    label: "Denmark",
    code: "45",
    id: "DK",
  },
  {
    label: "Djibouti",
    code: "253",
    id: "DJ",
  },
  {
    label: "Dominica",
    code: "1767",
    id: "DM",
  },
  {
    label: "Dominican Republic",
    code: "1849",
    id: "DO",
  },
  {
    label: "Ecuador",
    code: "593",
    id: "EC",
  },
  {
    label: "Egypt",
    code: "20",
    id: "EG",
  },
  {
    label: "El Salvador",
    code: "503",
    id: "SV",
  },
  {
    label: "Equatorial Guinea",
    code: "240",
    id: "GQ",
  },
  {
    label: "Eritrea",
    code: "291",
    id: "ER",
  },
  {
    label: "Estonia",
    code: "372",
    id: "EE",
  },
  {
    label: "Ethiopia",
    code: "251",
    id: "ET",
  },
  {
    label: "Falkland Islands (Malvinas)",
    code: "500",
    id: "FK",
  },
  {
    label: "Faroe Islands",
    code: "298",
    id: "FO",
  },
  {
    label: "Fiji",
    code: "679",
    id: "FJ",
  },
  {
    label: "Finland",
    code: "358",
    id: "FI",
  },
  {
    label: "France",
    code: "33",
    id: "FR",
  },
  {
    label: "French Guiana",
    code: "594",
    id: "GF",
  },
  {
    label: "French Polynesia",
    code: "689",
    id: "PF",
  },
  {
    label: "Gabon",
    code: "241",
    id: "GA",
  },
  {
    label: "Gambia",
    code: "220",
    id: "GM",
  },
  {
    label: "Georgia",
    code: "995",
    id: "GE",
  },
  {
    label: "Germany",
    code: "49",
    id: "DE",
  },
  {
    label: "Ghana",
    code: "233",
    id: "GH",
  },
  {
    label: "Gibraltar",
    code: "350",
    id: "GI",
  },
  {
    label: "Greece",
    code: "30",
    id: "GR",
  },
  {
    label: "Greenland",
    code: "299",
    id: "GL",
  },
  {
    label: "Grenada",
    code: "1473",
    id: "GD",
  },
  {
    label: "Guadeloupe",
    code: "590",
    id: "GP",
  },
  {
    label: "Guam",
    code: "1671",
    id: "GU",
  },
  {
    label: "Guatemala",
    code: "502",
    id: "GT",
  },
  {
    label: "Guernsey",
    code: "44",
    id: "GG",
  },
  {
    label: "Guinea",
    code: "224",
    id: "GN",
  },
  {
    label: "Guinea-Bissau",
    code: "245",
    id: "GW",
  },
  {
    label: "Guyana",
    code: "595",
    id: "GY",
  },
  {
    label: "Haiti",
    code: "509",
    id: "HT",
  },
  {
    label: "Holy See (Vatican City State)",
    code: "379",
    id: "VA",
  },
  {
    label: "Honduras",
    code: "504",
    id: "HN",
  },
  {
    label: "Hong Kong",
    code: "852",
    id: "HK",
  },
  {
    label: "Hungary",
    code: "36",
    id: "HU",
  },
  {
    label: "Iceland",
    code: "354",
    id: "IS",
  },
  {
    label: "India",
    code: "91",
    id: "IN",
  },
  {
    label: "Indonesia",
    code: "62",
    id: "ID",
  },
  {
    label: "Iran, Islamic Republic of Persian Gulf",
    code: "98",
    id: "IR",
  },
  {
    label: "Iraq",
    code: "964",
    id: "IQ",
  },
  {
    label: "Ireland",
    code: "353",
    id: "IE",
  },
  {
    label: "Isle of Man",
    code: "44",
    id: "IM",
  },
  {
    label: "Israel",
    code: "972",
    id: "IL",
  },
  {
    label: "Italy",
    code: "39",
    id: "IT",
  },
  {
    label: "Jamaica",
    code: "1876",
    id: "JM",
  },
  {
    label: "Japan",
    code: "81",
    id: "JP",
  },
  {
    label: "Jersey",
    code: "44",
    id: "JE",
  },
  {
    label: "Jordan",
    code: "962",
    id: "JO",
  },
  {
    label: "Kazakhstan",
    code: "77",
    id: "KZ",
  },
  {
    label: "Kenya",
    code: "254",
    id: "KE",
  },
  {
    label: "Kiribati",
    code: "686",
    id: "KI",
  },
  {
    label: "Korea, Democratic People's Republic of Korea",
    code: "850",
    id: "KP",
  },
  {
    label: "Korea, Republic of South Korea",
    code: "82",
    id: "KR",
  },
  {
    label: "Kuwait",
    code: "965",
    id: "KW",
  },
  {
    label: "Kyrgyzstan",
    code: "996",
    id: "KG",
  },
  {
    label: "Laos",
    code: "856",
    id: "LA",
  },
  {
    label: "Latvia",
    code: "371",
    id: "LV",
  },
  {
    label: "Lebanon",
    code: "961",
    id: "LB",
  },
  {
    label: "Lesotho",
    code: "266",
    id: "LS",
  },
  {
    label: "Liberia",
    code: "231",
    id: "LR",
  },
  {
    label: "Libyan Arab Jamahiriya",
    code: "218",
    id: "LY",
  },
  {
    label: "Liechtenstein",
    code: "423",
    id: "LI",
  },
  {
    label: "Lithuania",
    code: "370",
    id: "LT",
  },
  {
    label: "Luxembourg",
    code: "352",
    id: "LU",
  },
  {
    label: "Macao",
    code: "853",
    id: "MO",
  },
  {
    label: "Macedonia",
    code: "389",
    id: "MK",
  },
  {
    label: "Madagascar",
    code: "261",
    id: "MG",
  },
  {
    label: "Malawi",
    code: "265",
    id: "MW",
  },
  {
    label: "Malaysia",
    code: "60",
    id: "MY",
  },
  {
    label: "Maldives",
    code: "960",
    id: "MV",
  },
  {
    label: "Mali",
    code: "223",
    id: "ML",
  },
  {
    label: "Malta",
    code: "356",
    id: "MT",
  },
  {
    label: "Marshall Islands",
    code: "692",
    id: "MH",
  },
  {
    label: "Martinique",
    code: "596",
    id: "MQ",
  },
  {
    label: "Mauritania",
    code: "222",
    id: "MR",
  },
  {
    label: "Mauritius",
    code: "230",
    id: "MU",
  },
  {
    label: "Mayotte",
    code: "262",
    id: "YT",
  },
  {
    label: "Mexico",
    code: "52",
    id: "MX",
  },
  {
    label: "Micronesia, Federated States of Micronesia",
    code: "691",
    id: "FM",
  },
  {
    label: "Moldova",
    code: "373",
    id: "MD",
  },
  {
    label: "Monaco",
    code: "377",
    id: "MC",
  },
  {
    label: "Mongolia",
    code: "976",
    id: "MN",
  },
  {
    label: "Montenegro",
    code: "382",
    id: "ME",
  },
  {
    label: "Montserrat",
    code: "1664",
    id: "MS",
  },
  {
    label: "Morocco",
    code: "212",
    id: "MA",
  },
  {
    label: "Mozambique",
    code: "258",
    id: "MZ",
  },
  {
    label: "Myanmar",
    code: "95",
    id: "MM",
  },
  {
    label: "Namibia",
    code: "264",
    id: "NA",
  },
  {
    label: "Nauru",
    code: "674",
    id: "NR",
  },
  {
    label: "Nepal",
    code: "977",
    id: "NP",
  },
  {
    label: "Netherlands",
    code: "31",
    id: "NL",
  },
  {
    label: "Netherlands Antilles",
    code: "599",
    id: "AN",
  },
  {
    label: "New Caledonia",
    code: "687",
    id: "NC",
  },
  {
    label: "New Zealand",
    code: "64",
    id: "NZ",
  },
  {
    label: "Nicaragua",
    code: "505",
    id: "NI",
  },
  {
    label: "Niger",
    code: "227",
    id: "NE",
  },
  {
    label: "Nigeria",
    code: "234",
    id: "NG",
  },
  {
    label: "Niue",
    code: "683",
    id: "NU",
  },
  {
    label: "Norfolk Island",
    code: "672",
    id: "NF",
  },
  {
    label: "Northern Mariana Islands",
    code: "1670",
    id: "MP",
  },
  {
    label: "Norway",
    code: "47",
    id: "NO",
  },
  {
    label: "Oman",
    code: "968",
    id: "OM",
  },
  {
    label: "Pakistan",
    code: "92",
    id: "PK",
  },
  {
    label: "Palau",
    code: "680",
    id: "PW",
  },
  {
    label: "Palestinian Territory, Occupied",
    code: "970",
    id: "PS",
  },
  {
    label: "Panama",
    code: "507",
    id: "PA",
  },
  {
    label: "Papua New Guinea",
    code: "675",
    id: "PG",
  },
  {
    label: "Paraguay",
    code: "595",
    id: "PY",
  },
  {
    label: "Peru",
    code: "51",
    id: "PE",
  },
  {
    label: "Philippines",
    code: "63",
    id: "PH",
  },
  {
    label: "Pitcairn",
    code: "872",
    id: "PN",
  },
  {
    label: "Poland",
    code: "48",
    id: "PL",
  },
  {
    label: "Portugal",
    code: "351",
    id: "PT",
  },
  {
    label: "Puerto Rico",
    code: "1939",
    id: "PR",
  },
  {
    label: "Qatar",
    code: "974",
    id: "QA",
  },
  {
    label: "Romania",
    code: "40",
    id: "RO",
  },
  {
    label: "Russia",
    code: "7",
    id: "RU",
  },
  {
    label: "Rwanda",
    code: "250",
    id: "RW",
  },
  {
    label: "Reunion",
    code: "262",
    id: "RE",
  },
  {
    label: "Saint Barthelemy",
    code: "590",
    id: "BL",
  },
  {
    label: "Saint Helena, Ascension and Tristan Da Cunha",
    code: "290",
    id: "SH",
  },
  {
    label: "Saint Kitts and Nevis",
    code: "1869",
    id: "KN",
  },
  {
    label: "Saint Lucia",
    code: "1758",
    id: "LC",
  },
  {
    label: "Saint Martin",
    code: "590",
    id: "MF",
  },
  {
    label: "Saint Pierre and Miquelon",
    code: "508",
    id: "PM",
  },
  {
    label: "Saint Vincent and the Grenadines",
    code: "1784",
    id: "VC",
  },
  {
    label: "Samoa",
    code: "685",
    id: "WS",
  },
  {
    label: "San Marino",
    code: "378",
    id: "SM",
  },
  {
    label: "Sao Tome and Principe",
    code: "239",
    id: "ST",
  },
  {
    label: "Saudi Arabia",
    code: "966",
    id: "SA",
  },
  {
    label: "Senegal",
    code: "221",
    id: "SN",
  },
  {
    label: "Serbia",
    code: "381",
    id: "RS",
  },
  {
    label: "Seychelles",
    code: "248",
    id: "SC",
  },
  {
    label: "Sierra Leone",
    code: "232",
    id: "SL",
  },
  {
    label: "Singapore",
    code: "65",
    id: "SG",
  },
  {
    label: "Slovakia",
    code: "421",
    id: "SK",
  },
  {
    label: "Slovenia",
    code: "386",
    id: "SI",
  },
  {
    label: "Solomon Islands",
    code: "677",
    id: "SB",
  },
  {
    label: "Somalia",
    code: "252",
    id: "SO",
  },
  {
    label: "South Africa",
    code: "27",
    id: "ZA",
  },
  {
    label: "South Sudan",
    code: "211",
    id: "SS",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    code: "500",
    id: "GS",
  },
  {
    label: "Spain",
    code: "34",
    id: "ES",
  },
  {
    label: "Sudan",
    code: "249",
    id: "SD",
  },
  {
    label: "Surilabel",
    code: "597",
    id: "SR",
  },
  {
    label: "Svalbard and Jan Mayen",
    code: "47",
    id: "SJ",
  },
  {
    label: "Swaziland",
    code: "268",
    id: "SZ",
  },
  {
    label: "Sweden",
    code: "46",
    id: "SE",
  },
  {
    label: "Switzerland",
    code: "41",
    id: "CH",
  },
  {
    label: "Syrian Arab Republic",
    code: "963",
    id: "SY",
  },
  {
    label: "Taiwan",
    code: "886",
    id: "TW",
  },
  {
    label: "Tajikistan",
    code: "992",
    id: "TJ",
  },
  {
    label: "Tanzania, United Republic of Tanzania",
    code: "255",
    id: "TZ",
  },
  {
    label: "Thailand",
    code: "66",
    id: "TH",
  },
  {
    label: "Timor-Leste",
    code: "670",
    id: "TL",
  },
  {
    label: "Togo",
    code: "228",
    id: "TG",
  },
  {
    label: "Tokelau",
    code: "690",
    id: "TK",
  },
  {
    label: "Tonga",
    code: "676",
    id: "TO",
  },
  {
    label: "Trinidad and Tobago",
    code: "1868",
    id: "TT",
  },
  {
    label: "Tunisia",
    code: "216",
    id: "TN",
  },
  {
    label: "Turkey",
    code: "90",
    id: "TR",
  },
  {
    label: "Turkmenistan",
    code: "993",
    id: "TM",
  },
  {
    label: "Turks and Caicos Islands",
    code: "1649",
    id: "TC",
  },
  {
    label: "Tuvalu",
    code: "688",
    id: "TV",
  },
  {
    label: "Uganda",
    code: "256",
    id: "UG",
  },
  {
    label: "Ukraine",
    code: "380",
    id: "UA",
  },
  {
    label: "United Arab Emirates",
    code: "971",
    id: "AE",
  },
  {
    label: "United Kingdom",
    code: "44",
    id: "GB",
  },
  {
    label: "United States",
    code: "1",
    id: "US",
  },
  {
    label: "Uruguay",
    code: "598",
    id: "UY",
  },
  {
    label: "Uzbekistan",
    code: "998",
    id: "UZ",
  },
  {
    label: "Vanuatu",
    code: "678",
    id: "VU",
  },
  {
    label: "Venezuela, Bolivarian Republic of Venezuela",
    code: "58",
    id: "VE",
  },
  {
    label: "Vietnam",
    code: "84",
    id: "VN",
  },
  {
    label: "Virgin Islands, British",
    code: "1284",
    id: "VG",
  },
  {
    label: "Virgin Islands, U.S.",
    code: "1340",
    id: "VI",
  },
  {
    label: "Wallis and Futuna",
    code: "681",
    id: "WF",
  },
  {
    label: "Yemen",
    code: "967",
    id: "YE",
  },
  {
    label: "Zambia",
    code: "260",
    id: "ZM",
  },
  {
    label: "Zimbabwe",
    code: "263",
    id: "ZW",
  },
];
