import { Navigate, Route, Routes } from "react-router-dom";
import { RecordViewContainer } from "./components/record/view/recordView.container";
import { RecordUpdateContainer } from "./components/record/update/recordUpdate.container";
import { VehicleViewContainer } from "./components/vehicle/view/vehicleView.container";
import { VehicleSearchRoute } from "./components/vehicle/search/vehicleSearch.route";
import { AdminContainer } from "./components/admin/admin.container";
import { useAppSelector } from "./state/store";
import { isAdmin } from "./util/helper";
import { VehicleUpdateContainer } from "./components/vehicle/update/vehicleUpdate.container";
import { UserUpdateContainer } from "./components/user/update/userUpdate.container";
import { ProtectedRoute } from "./ProtectedRoute";
import { LoginContainer } from "./components/user/login.container";
import { RecordCreateRoute } from "./components/record/create/recordCreate.route";
import { FavouritesContainer } from "./components/favourites/favorites.container";
import { PartCreateContainer } from "./components/parts/create/partCreate.container";
import { PartViewContainer } from "./components/parts/view/partView.container";
import { PartUpdateContainer } from "./components/parts/update/partUpdate.container";
import { HomeContainer } from "./components/intro/home.container";
import { PartsListRoute } from "./components/parts/list/partsList.route";
import { VehicleListRoute } from "./components/vehicle/list/vehicleList.route";
import { VehicleCreateRoute } from "./components/vehicle/create/vehicleCreate.route";
import { ListingType } from "./enum/vehicleListing.enum";
import { ItemViewMode } from "./enum/itemViewMode.enum";
import { LocalStorageKeys } from "./enum/localStorageKeys.enum";
import { useUser } from "./hooks/useUser";
import { OwnerTypes } from "./enum/ownerTypes.enum";
import { UserVehicleViewContainer } from "./components/vehicle/view/userVehicleView.container";

// TODO: all update paths needs to be secured with ownership of that resource
export const Router = () => {
  const { ready } = useAppSelector((state) => state.app);
  const { user } = useUser();

  if (!ready) {
    return <></>;
  }

  return (
    <Routes>
      <Route path={""} element={<HomeContainer />} />

      <Route path={"/user"}>
        <Route path={"edit"} element={<ProtectedRoute><UserUpdateContainer /></ProtectedRoute>} />
      </Route>

      <Route path={"/login"} element={user ? <Navigate to="/" replace /> : <LoginContainer />} />
      <Route path={"favourites"} element={<ProtectedRoute><FavouritesContainer /></ProtectedRoute>} />

      <Route path={"sparepart"}>
        <Route path={":pid/update"} element={<ProtectedRoute><PartUpdateContainer /></ProtectedRoute>} />
        <Route path={"shop/:sellerId"} element={<PartsListRoute showBusinessCover={true} partsFilterKey={LocalStorageKeys.shopPartsFilter} viewMode={ItemViewMode.grid} title="" subTitle="" ownerType={OwnerTypes.ProvidedAsParam} />} />
        <Route path={"mySales"} element={<ProtectedRoute><PartsListRoute showBusinessCover={true} partsFilterKey={LocalStorageKeys.mySalesPartsFilter} viewMode={ItemViewMode.table} title="Your SpareParts Listings" subTitle="Your Spareparts Sale Advertisements" ownerType={OwnerTypes.My} /></ProtectedRoute>} />
        <Route path={"market"} element={<PartsListRoute showBusinessCover={false} partsFilterKey={LocalStorageKeys.marketPartsFilter} viewMode={ItemViewMode.grid} title="Spare Parts For Sale" subTitle="Find Spareparts for your vehicle" ownerType={OwnerTypes.Any} />} />
        <Route path={"create"} element={<ProtectedRoute><PartCreateContainer /></ProtectedRoute>} />
        <Route path={":pid"} element={<PartViewContainer />} />
      </Route>

      <Route path={"vehicle"}>
        <Route path={":vid/record/:rid/update"} element={<ProtectedRoute><RecordUpdateContainer /></ProtectedRoute>} />
        <Route path={":vid/record/:rid"} element={<RecordViewContainer />} />
        <Route path={":vid/record/create"} element={<ProtectedRoute><RecordCreateRoute /></ProtectedRoute>} />
        <Route path={":vid/update"} element={<ProtectedRoute><VehicleUpdateContainer title="Edit Vehicle" subTitle="Change the existing settings of the vehicle" /></ProtectedRoute>} />
        <Route path={"shop/:sellerId"} element={<VehicleListRoute filterStorageKey={LocalStorageKeys.shopVehiclesFilter} viewMode={ItemViewMode.grid} title="" subTitle="SHOP PARTS HERE " buttonSubText="For Your Market" listedFor={ListingType.ListingForSale} ownerType={OwnerTypes.ProvidedAsParam} showBusinessCover={true} />} />
        <Route path={"mySales"} element={<ProtectedRoute><VehicleListRoute filterStorageKey={LocalStorageKeys.mySalesVehiclesFilter} viewMode={ItemViewMode.table} title="Your Vehicles Listings" subTitle="Your Vehicle Sale Advertisements" buttonSubText="For Your Market" listedFor={ListingType.ListingForSale} ownerType={OwnerTypes.My} showBusinessCover={true} /></ProtectedRoute>} />
        <Route path={"search"} element={<VehicleSearchRoute />} />
        <Route path={"market"} element={<VehicleListRoute filterStorageKey={LocalStorageKeys.marketVehiclesFilter} viewMode={ItemViewMode.grid} title="Vehicles For Sale" subTitle="Find best matching vehicle for you" buttonSubText="For Sale" listedFor={ListingType.ListingForSale} ownerType={OwnerTypes.Any} showBusinessCover={false} />} />
        <Route path={"create"} element={<ProtectedRoute><VehicleCreateRoute title="Add Vehicle" subTitle="Add new vehicle for" /></ProtectedRoute>} />
        <Route path={"myOwn"} element={<ProtectedRoute>
          <VehicleListRoute filterStorageKey={LocalStorageKeys.myAccountVehiclesFilter} viewMode={ItemViewMode.table} title="Your Owned Vehicles" subTitle="Maintain The Service Records For Your Vehicle" buttonSubText="For Your Profile" listedFor={ListingType.ListingForMyOwn} ownerType={OwnerTypes.My} showBusinessCover={false} hideFilter />
          <UserVehicleViewContainer />
        </ProtectedRoute>} />
        <Route path={":vid"} element={<VehicleViewContainer />} />
      </Route>

      {isAdmin(user) && <Route path={"admin"}>
        <Route path={""} element={<AdminContainer />} />
      </Route>}
    </Routes>
  )
};
