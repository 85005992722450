import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteVehicleMutation,
  useGetVehicleByIdQuery,
  useUpdateVehicleMutation,
} from "../../../state/api/vehicle.api";
import { Vehicle } from "../../../types/Vehicle";
import { VehicleView } from "./vehicleView";
import { RecordsListContainer } from "../../record/list/recordsList.container";
import { calcFuelConsumption, parseEntityId } from "../../../util/helper";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import { VehicleMoreDetail } from "./vehicleMoreDetail";
import { VehicleActions } from "./vehicleActions";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { useLazySearchRecordsQuery } from "../../../state/api/record.api";
import { useEffect } from "react";
import { config } from "../../../config";
import { useUser } from "../../../hooks/useUser";
import { onLeaveVehicleView } from "../../../state/slice/appSlice";
import { useAppDispatch } from "../../../state/store";
import { useUpdateUserMutation } from "../../../state/api/user.api";
import { ServiceType } from "../../../selectOptions/serviceTypes";
import { useModels } from "../../../hooks/useModels";
import { PopupManager } from "../../../util/popupManager";
import { Grid, Typography } from "@mui/material";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { useOwnVehicles } from "../../../hooks/useOwnVehicles";

export const VehicleViewContainer = () => {
  const { vid } = useParams();
  const { user } = useUser();
  const { isOwnVehicle } = useOwnVehicles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const models = useModels();

  useBreadcrumb([{
    label: 'Home',
    path: ``
  }]);

  useEffect(() => {
    const path = location.pathname + location.search;
    dispatch(onLeaveVehicleView(path));
  }, [location]);

  const navigate = useNavigate();

  const { data: vehicle, isLoading } = useGetVehicleByIdQuery({ id: parseEntityId(vid), ownVehicle: isOwnVehicle(vid) });
  const [searchFuelRecords] = useLazySearchRecordsQuery();

  const isOwner = user?.id === vehicle?.id;

  const [updateUser, { isLoading: loadingUserUpdate, data: updatedUser }] = useUpdateUserMutation();
  const [updateVehicle, { isLoading: loadingUpdate }] = useUpdateVehicleMutation();
  const [deleteVehicle, { isLoading: loadingDelete }] = useDeleteVehicleMutation();

  const handleOnEdit = (v: Vehicle) => {
    navigate(`update`);
  };

  const changeOwnership = async (vehicle: Vehicle, newUserId: number) => {
    PopupManager.showConfirmBox('', newUserId === config.adminUserId ? 'This vehicle will be removed from your profile' : 'This vehicle will be added to your profile', 'boolean').then(async (result) => {
      if (result.confirmed) {
        const veh = { ...vehicle };
        veh.UserId = newUserId;
        const result: any = await updateVehicle({ veh, imgs: {} });
        if (result.data) {
          PopupManager.showSuccessToast(`Vehicle ${newUserId === config.adminUserId ? 'is removed' : 'is added'} successfully`);
          navigate(`/vehicle/myOwn`);
        }
      } else {
        // Do nothing
      }
    });
  };

  const handleDelete = (v: Vehicle) => {
    PopupManager.showConfirmBox('Are You Sure you want to delete this vehicle?', '**** We recommend to use REMOVE_FROM_PROFILE button **** Otherwise this action will remove all the records and vehicle information from OdoCert and future users will not able to see this vehicle.', 'boolean').then(async (res) => {
      if (res.confirmed) {
        PopupManager.showSuccessToast('Vehicle is deleting...');
        if (v.id) {
          const result: any = await deleteVehicle(v.id);
          if (!result.error) {
            PopupManager.showSuccessToast('Vehicle deleted');
            navigate(`/`);
          } else {
            PopupManager.showConfirmBox('You have added history records for this vehicle', 'Press OK to remove vehicle from your profile while keeping the records in the database for the community.', 'boolean', '(Or else, try again later after deleting all the history records!)').then(result => {
              if (result.confirmed) {
                changeOwnership(v, config.adminUserId);
              } else {
                // Do nothing
              }
            })
          }
        };
      } else {
        // Do nothing
      }
    });
  }

  const handleChangeListing = (v: Vehicle, isSell: boolean) => {
    const title = isSell ? 'Do you want to sell this vehicle on Odocert' : 'Do you want to stop selling this vehicle and add it back to your own vehicles';

    PopupManager.showConfirmBox('Are You Sure?', title, 'boolean').then(async (res) => {
      if (res.confirmed) {
        PopupManager.showSuccessToast(`Listing vehicle for ${isSell ? 'sale' : 'your own'} ...`);
        const veh = { ...v };
        veh.listing = isSell ? ListingType.ListingForSale : ListingType.ListingForMyOwn;
        const result: any = await updateVehicle({ veh, imgs: {} });
        if (result.data) {
          PopupManager.showSuccessToast(`Vehicle listed for ${isSell ? 'sale' : 'your own'} successfully. You may have to edit it with required values`);
          navigate(`/vehicle/${v.id}/update`);
        }
      } else {
        // Do nothing
      }
    });
  }

  const handleUpdate = async (veh: Vehicle) => {
    const result: any = await updateVehicle({ veh, imgs: {} });
    if (result.data) {
      PopupManager.showSuccessToast(`Vehicle updated successfully.`);
    }
  }

  const handleFuelStatRequest = async (): Promise<string> => {
    const result = await searchFuelRecords({ isOwner, filter: { VehicleId: parseEntityId(vid), categories: [ServiceType.FuelTopUp] }, offset: 0, limit: config.fuelRecordsPageSize });
    return calcFuelConsumption(result.data);
  }

  if (isLoading) {
    return <LoaderBackdrop />
  }

  if(!vehicle){
    return <></>
  }
  
  const favoriteList = (updatedUser ?? user)?.favouriteVehicles;
  const isFavourite = favoriteList?.includes(vehicle.id) ?? false;

  const handleFavouriteChange = async (fav: boolean) => {
    if (user) {
      const newUser = { ...user };
      if (fav) {
        if (!newUser.favouriteVehicles) {
          newUser.favouriteVehicles = [];
        }
        if (!newUser.favouriteVehicles.includes(vehicle.id)) {
          newUser.favouriteVehicles = [...newUser.favouriteVehicles, vehicle.id];
        }
      } else {
        newUser.favouriteVehicles = newUser.favouriteVehicles.filter(f => f !== vehicle.id)
      }
      await updateUser({ user: newUser })
      if (fav) {
        PopupManager.showSuccessToast('Added to favourites');
      } else {
        PopupManager.showSuccessToast('Removed from favourites');
      }
    } else {
      PopupManager.showErrorToast('Please login to add favorites');
    }
  }

  return (
    <>
      {loadingDelete && <LoaderBackdrop />}
      <VehicleView
        models={models}
        showFavourite={user !== null}
        isFavourite={isFavourite}
        loadingFavorite={loadingUserUpdate}
        loading={isLoading || loadingUpdate}
        vehicle={vehicle}
        onFavouriteChange={handleFavouriteChange}
      />

      {user?.id === config.adminUserId && <Grid container item justifyContent="end">
        <Grid>
          <Typography justifyContent="end" variant="subtitle2">YOU ARE ADMIN</Typography>
        </Grid>
      </Grid>}

      {user?.id && <VehicleActions v={vehicle} onUpdate={handleUpdate} newUserId={user.id} onDelete={handleDelete} onChangeListing={handleChangeListing} onEdit={handleOnEdit} onChangeOwnership={changeOwnership} />}

      <VehicleMoreDetail state={vehicle} onRequestFuelStat={handleFuelStatRequest} />

      {/* Record filters might be changed and RecordsList should be re-rendered when user changes the filters */}
      {vehicle && <RecordsListContainer key={location.search} />}
    </>
  );
};
