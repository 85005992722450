export enum CacheStoreKey {
    // This file's copy is used in BE

    globalCacheStampVehicles = 'globalCacheStampVehicles',
    globalCacheStampParts = 'globalCacheStampParts',
    globalCacheStampRecords = 'globalCacheStampRecords',
    
    metaModelDBStamp = 'metaModelDBStamp',
    metaBrandDBStamp = 'metaBrandDBStamp',
    metaAreaDBStamp = 'metaAreaDBStamp',

    // User Model Related
    userCacheStampVehicles = 'userCacheStampVehicles',
    userCacheStampParts = 'userCacheStampParts',
    userCacheStampRecords = 'userCacheStampRecords',
    userCacheStamp = 'userCacheStamp',

    // allResetExceptUserStamp = 'allResetExceptUserStamp'
  }
  