import axios from "axios";
import { StorageService } from "../services/storage.service";

export class Persister {
    public static async requestData(type: 'POST' | 'GET', endpointUrl: string, body?: object,) {

        if (endpointUrl.includes('cached=true')) {
            const requestStringWithBody = `${endpointUrl}${JSON.stringify(body)}`;
            const localResult = StorageService.getNetworkItem(requestStringWithBody);

            if (localResult) {
                // console.warn('cache hit', requestStringWithBody);
                return JSON.parse(localResult);
            } else {
                // console.error('cache mis', requestStringWithBody);
                const response = type === 'POST' ? await axios.post(endpointUrl, body) : await axios.get(endpointUrl);

                StorageService.getAllNetworkKeys().forEach(key => {
                    const keyBeforeStamp = key.split('stamp')[0];
                    if (requestStringWithBody.startsWith(keyBeforeStamp)) {
                        StorageService.clearItem(key);
                    }
                });

                // cache only the succeed responses
                if (response.data.status) {
                    StorageService.setNetworkItem(requestStringWithBody, JSON.stringify(response));
                }
                return response;
            }
        } else {
            const response = type === 'POST' ? await axios.post(endpointUrl, body) : await axios.get(endpointUrl);
            return response;
        }
    }
}