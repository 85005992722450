import { UserType } from "../enum/user.type";
import { AuthProvider } from "../enum/authProvider.enum";
import { User } from "../types/User";

const firstCacheStamp = `${new Date().getTime()}`;

export const defaultUser: Omit<User, "id"> = {
  type: UserType.User,
  name: "",
  
  email: null,
  phone: '',
  
  shopName: '',
  shopAddress: '',
  shopPhone: '',
 
  authProvider: AuthProvider.odocert,
  identifier: "",
  country: "LK",


  extraProps: {},
  images: {},

  favouriteVehicles: [],
  favouriteParts: [],
  updatedAt: new Date(),
  createdAt: new Date(),

  userCacheStamp: firstCacheStamp,
  userCacheStampVehicles: firstCacheStamp,
  userCacheStampParts: firstCacheStamp,
  userCacheStampRecords: firstCacheStamp,
}