import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC } from "react";

import { ConfigProp } from "../../../types/ConfigProp";
import { CustomButton } from "../../shared/CustomButton";
import { Restore } from "@mui/icons-material";
import { CacheStoreKey } from "../../../enum/cacheStore.enum";

interface Props {
    items: ConfigProp[];
    loading: boolean;
    onReset: (type: CacheStoreKey) => void;
}

export const ConfigPropsAdditions: FC<Props> = ({ items, onReset }) => {

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Config Key</TableCell>
                        <TableCell>Config Value</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items?.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.key}</TableCell>
                            <TableCell>{row.value}</TableCell>
                            <TableCell><CustomButton size="small" label="Reset Stamp" onClick={() => onReset(row.key)} EndIcon={Restore}></CustomButton></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}