import { useNavigate, useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import { useUser } from "../../../hooks/useUser";
import {
  useDeleteRecordMutation,
  useReadRecordQuery,
} from "../../../state/api/record.api";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { RecordView } from "./recordView";
import { PopupManager } from "../../../util/popupManager";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { useOwnRecords } from "../../../hooks/useOwnRecords";

export const RecordViewContainer = () => {
  const { rid, vid } = useParams();
  const { user } = useUser();
  const { isOwnRecord } = useOwnRecords();

  useBreadcrumb([{
    label: 'Home',
    path: ``
  },
  {
    label: 'Vehicle',
    path: `/vehicle/${vid}`
  },
  ]);

  const { data: record, isLoading } = useReadRecordQuery({ id: rid ?? "-1", isOwner: isOwnRecord(rid) });
  const navigate = useNavigate();
  const [deleteRecord, {isLoading: isLoadingDelete}] = useDeleteRecordMutation();

  const handleEdit = (r: ServiceRecord) => {
    navigate(`update`);
  };

  const handleDelete = async (r: ServiceRecord) => {
    if (r.id) {
      await deleteRecord(r);
      navigate(`/vehicle/${vid}`);
    } else {
      PopupManager.showErrorToast("Record id not found");
    }
  };

  return (
    <>
      {isLoadingDelete && <LoaderBackdrop />}
      <RecordView
        user={user}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={isLoading}
        record={record} />
    </>
  );
};
