import { LoaderLinear } from "../../loader/loaderLinear.component";
import { Typography } from "@mui/material";
import { ConfigPropsAdditions } from "./configPropsAdditions";
import { useReadConfigPropsQuery, useResetConfigPropMutation } from "../../../state/api/configProps.api";
import { CustomButton } from "../../shared/CustomButton";
import { Restore } from "@mui/icons-material";
import { PopupManager } from "../../../util/popupManager";
import { CacheStoreKey } from "../../../enum/cacheStore.enum";

export const ConfigPropsAdditionsContainer = () => {

    const { data, isLoading: isLoadingConfigProps, refetch } = useReadConfigPropsQuery({});
    const [resetConfigProp, { isLoading: loadingReset }] = useResetConfigPropMutation();

    if (isLoadingConfigProps) {
        return <LoaderLinear />
    }

    const resetAllConfigProps = async () => {
        try {
            await resetConfigProp('all');
            PopupManager.showSuccessToast('Config props reset completed');
        } catch (e) {
            PopupManager.showErrorToast('COnfig props reset failed')
        }
    }


    const resetConfigProps = async (type: CacheStoreKey) => {
        try {
            await resetConfigProp(type);
            PopupManager.showSuccessToast('Config props reset completed');
        } catch (e) {
            PopupManager.showErrorToast('COnfig props reset failed')
        }
    }

    return (
        <>
            <Typography variant="subtitle1">Config Props Additions</Typography> 
             <CustomButton label="Reset Config Props" onClick={resetAllConfigProps} EndIcon={Restore}></CustomButton>
            <ConfigPropsAdditions
                loading={isLoadingConfigProps}
                items={data}
                onReset={resetConfigProps}
            />
        </>
    );
}