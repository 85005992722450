import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SearchParams } from "../../../enum/searchParams.enum";
import { useGetVehicleByIdQuery } from "../../../state/api/vehicle.api";
import { LoaderLinear } from "../../loader/loaderLinear.component";
import { FuelTopup } from "./fuelTopup";
import { ListingType } from "../../../enum/vehicleListing.enum";

export interface CProps {
    vid: number;
    extraProps: any;
    onExtraChange: (obj: any) => void;
    onReady: (status: boolean) => void;
    loading: boolean;
    ready: boolean;
}
export const FuelTopupContainer: FC<CProps> = ({ extraProps, vid, onExtraChange, loading, onReady, ready }) => {

    // Fuel topup happens only for own vehicles
    const { data: vehicle, isLoading } = useGetVehicleByIdQuery({id: vid, ownVehicle: true});
    const navigate = useNavigate();

    useEffect(() => {
        if(vehicle){
            onReady(vehicle.fuelPrice > 0);
        } else {
            console.error('FuelTopupContainer: vehicle not found');
        }

        return () => {
            onReady(true);
        }
    }, [vehicle]);

    const handleFuelPriceChange = () => {
        // step 3 includs the fuel price
        navigate(`/vehicle/${vid}/update?step=3&fromAddRecord=true&${SearchParams.vehicleListing}=${ListingType.ListingForMyOwn}`);
    }

    if (isLoading || !vehicle) {
        return <LoaderLinear />
    }

    return <FuelTopup ready={ready} vehicle={vehicle} extraProps={extraProps} onExtraChange={onExtraChange} onFuelPriceChange={handleFuelPriceChange} loading={loading} />
}