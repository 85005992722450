import { FC, useEffect, useState } from "react";
import { TextInput, TextInputProps } from "./TextInput";
import { phone } from 'phone';
import { Grid } from "@mui/material";
import { getountryCodeFromId } from "../../util/countryHelper";

export const PhoneInput: FC<TextInputProps & { country: string }> = (props) => {

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    const validatedResult = phone(`${props.value}`, { country: props.country });
    const phoneWithoutCountryCode = validatedResult.phoneNumber ? validatedResult.phoneNumber.split(validatedResult.countryCode)[1] : `${props.value}`;
    setValue(phoneWithoutCountryCode);
  }, [props])

  const handleOnChange = (key: string, value: number | string) => {
    const phoneNoWithoutCC = `${value}`;
    setValue(phoneNoWithoutCC);

    const result = phone(phoneNoWithoutCC, { country: props.country });
    const phoneWithCC = result.phoneNumber ? result.phoneNumber : value;

    // + sign is replaced with ' ' when it is sent with query params. So it is safe to not use that.
    props.onChange(key, `${phoneWithCC}`.replace('+', ''));
  }

  return (
    <Grid display="grid" gridTemplateColumns={"2fr 4fr"}>
      <Grid>
        <TextInput
          value={getountryCodeFromId(props.country)}
          name="identifier"
          label="Country"
          onChange={() => { }}
          disabled
          align="right"
          paddingRight={1}
        />
      </Grid>
      <Grid>
        <TextInput  {...props} onChange={handleOnChange} value={value} />
      </Grid>
    </Grid>
  );
};
