import { useUser } from "./useUser";
import { useSearchRecordsQuery } from "../state/api/record.api";
import { ServiceRecord } from "../types/ServiceRecord";

export const useOwnRecords = () => {
  const { user } = useUser();
  const { data } = useSearchRecordsQuery({
    limit: undefined,
    offset: 0,
    filter: {
      UserId: user.id
    },
    isOwner: true
  });
  const ownRecords = (data ?? []) as ServiceRecord[];

  const isOwnRecord = (rId: number | string | undefined) => {
    return ownRecords.find(r => r.id === Number(rId)) !== null;
  }
  return { ownRecords, isOwnRecord };
};
