import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RootState, useAppDispatch, useAppSelector } from '../../state/store';
import { updateConfirmBox } from '../../state/slice/appSlice';
import { Typography } from '@mui/material';
import { PhoneInput } from './PhoneInput';
import { AutoInput } from './AutoInput';
import { Validators } from '../../util/validators';
import { countryListWithCodes } from '../../util/countryHelper';
import { useFormState } from '../../hooks/useFormState';

export const ConfirmBox: React.FC = () => {

    const { isOpen, header, text, result, inputType, notes } = useAppSelector((state: RootState) => state.app.confirmBox);
    const dispatch = useAppDispatch();

    const { state, changeProperty } = useFormState({
        country: 'LK',
        phone: ''
    })

    const handleOk = () => {
        dispatch(updateConfirmBox({
            result: {
                confirmed: true,
                data: state
            }
        }));
    };

    const handleCancel = () => {
        dispatch(updateConfirmBox({
            result: {
                confirmed: false,
                data: null
            }
        }));
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={handleCancel}>
                <DialogTitle>{header}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                    <Typography variant='caption'>
                        {notes}
                    </Typography>
                    {inputType === 'phone' &&

                        <>
                            <AutoInput
                                value={state.country}
                                name="country"
                                error={!Validators.country(state.country)}
                                label="Select Country"
                                options={countryListWithCodes()}
                                disabled={false}
                                onChange={changeProperty}
                            />
                            <PhoneInput
                                name={'phone'}
                                value={state.phone}
                                label={'Enter phone of other user'}
                                onChange={changeProperty}
                                country={'LK'} />
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleOk}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}