import { useCreateRecordMutation } from "../../../state/api/record.api";
import { ErrorComponent } from "../../error/error";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { RecordCreate } from "./recordCreate";
import { useVehicleList } from "../../../hooks/useVehicleList";
import { Link, useNavigate } from "react-router-dom";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { FC, useEffect } from "react";
import { useGetVehicleByIdQuery } from "../../../state/api/vehicle.api";
import { useBreadcrumb } from "../../../hooks/useBreadcrumb";
import { useUser } from "../../../hooks/useUser";
import { SearchParams } from "../../../enum/searchParams.enum";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { LoaderLinear } from "../../loader/loaderLinear.component";

interface Props {
  vid: string;
}
export const RecordCreateContainer: FC<Props> = ({ vid }) => {
  const { user } = useUser();

  useBreadcrumb([{
    label: 'Home',
    path: `/`
  },
  {
    label: 'Vehicle',
    path: `/vehicle/${vid}`
  },
  ]);

  const { vehicleList, isLoading } = useVehicleList( user?.id, ListingType.ListingForMyOwn);
  // record create happens only for own vehicles
  const { data: vehicle } = useGetVehicleByIdQuery({ id: Number(vid), ownVehicle: true });
  const navigate = useNavigate();

  const initialState: Partial<ServiceRecord> = vid ? { VehicleId: Number(vid) } : {};

  useEffect(() => {
    if (vid === '-1' && vehicleList.length > 0) {
      navigate(`/vehicle/${vehicleList[0].id}/record/create`);
    }
  }, [vid, vehicleList, navigate]);

  const handleSelectedVehicleChange = (index: number) => {
    navigate(`/vehicle/${index}/record/create`);
  }

  const [createRecord, { isLoading: isLoadingCreate }] = useCreateRecordMutation();

  const handleSaveRecord = async (rec: ServiceRecord, imgs: Record<number, Blob | null>) => {
    const result =  await createRecord({ rec, imgs });

    // @ts-expect-error
    if (result.data && vid) {
      navigate(`/vehicle/${vid}`);
    }
  };

  if (!user) {
    return <ErrorComponent title="Error" text="User N/A" />;
  }

  if (isLoading) {
    return <LoaderBackdrop />;
  }

  if (vehicleList.length === 0 && !vehicle.users.includes(user.identifier)) {
    return <Link to={`/vehicle/create?${SearchParams.vehicleListing}=${ListingType.ListingForMyOwn}`} style={{color: 'red'}}>Add a vehicle before adding new records</Link>;
  }

  if(!vehicle){
    return <LoaderLinear />
  }

  return (
    <RecordCreate
      key={vid}
      vehicleListOfUser={vehicleList}
      onSelectedVehicleChange={handleSelectedVehicleChange}
      onSave={handleSaveRecord}
      loading={isLoading || isLoadingCreate}
      initialState={initialState}
      user={user}
      vehicle={vehicle}
    />
  );
};
