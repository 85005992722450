import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { Pagination } from "../../types/Pagination";
import { User } from "../../types/User";
import { resetCacheStamp, appendCacheStamp } from "../../util/cacheManager";
import { encryptProps, handleNetworkResponse } from "../../util/helper";
import { SocialUser } from "../../types/SocialUser";
import { CacheStoreKey } from "../../enum/cacheStore.enum";
import { NetworkResponse } from "../../types/NetworkResponse";
import { firebaseSignout, generateImagesArrJson, syncImages } from "../../services/firebase.service";
import { ImageFolder, SubImageFolder } from "../../enum/imageCategoryEnums";
import { UserImageType } from "../../enum/user.type";
import { AuthProvider } from "../../enum/authProvider.enum";
import { Persister } from "../../util/persister";
import { StorageService } from "../../services/storage.service";

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: [CacheStoreKey.userCacheStamp],
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: (build) => ({

    // mutation
    createUser: build.mutation({
      queryFn: async (args: { user: Omit<User, "id"> }) => {
        StorageService.clearAll();
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);
        args.user.identifier = args.user.identifier.toUpperCase();
        const response = await axios.post(`/user/signUp`, args.user);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // mutation
    signIn: build.mutation({
      queryFn: async (args: { identifier: string; password: string }) => {
        StorageService.clearAll();
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);
        const newArgs = { ...args };
        newArgs.identifier = newArgs.identifier.toUpperCase();
        const response = await axios.post(`/user/signIn`, newArgs);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // mutation
    socialSignIn: build.mutation({
      queryFn: async (args: { token: string, user: SocialUser }) => {
        StorageService.clearAll();
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);
        const response = await axios.post(`/user/socialSignIn`, args);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // Related to forgot password
    // mutation
    sendReset: build.mutation<NetworkResponse, string>({
      queryFn: async (identifier) => {
        const response = await axios.post(`/user/sendReset`, { identifier });
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // Related to forgot password
    // mutation
    verifyReset: build.mutation<NetworkResponse, { identifier: string; code: string }>({
      queryFn: async (args) => {
        const response = await axios.post(`/user/verifyReset`, args);
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // mutation
    changePassword: build.mutation({
      queryFn: async (args: { identifier: string; password: string }) => {
        // clearAllCookies();
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);
        const response = await axios.post(
          `/user/changePassword`,
          encryptProps(args)
        );
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // mutation
    updateUser: build.mutation({
      queryFn: async (args: {
        user: Partial<User>,
        imgs?: Record<number, Blob | null>
      }) => {
        if (args.imgs && args.user.id) {
          args.user.images = generateImagesArrJson(args.user.id, args.user.images, args.imgs, ImageFolder.User, args.user.id, [SubImageFolder.ProfileImages, UserImageType.BusinessCover]);
          await syncImages(args.user.id, ImageFolder.User, args.user.id, args.imgs, [SubImageFolder.ProfileImages, UserImageType.BusinessCover]);
        }
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);

        // TODO: check why the cache stamp is sent here
        const response = await axios.post(`/user/update`, {...args.user, cacheStamp});
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // mutation
    logout: build.mutation({
      queryFn: async (arg: { user: User }) => {
        const cacheStamp = resetCacheStamp(CacheStoreKey.userCacheStamp);
        StorageService.clearAll();
        if (arg.user.authProvider === AuthProvider.google || arg.user.authProvider === AuthProvider.facebook) {
          await firebaseSignout();
        }
        const response = await axios.post(`/user/logout`);
        return handleNetworkResponse(response, null);
      },
      invalidatesTags: [CacheStoreKey.userCacheStamp],
    }),

    // read
    getLoggedInUser: build.query({
      queryFn: async (args: { cached: boolean }) => {
        // const response = await axios.get<User>(setCacheStamp(`/user/loggedInUser?cached=${args.cached}`, CacheStoreKey.userCacheStamp));
        const response = await Persister.requestData('GET', appendCacheStamp(`/user/loggedInUser?cached=${args.cached}`, CacheStoreKey.userCacheStamp));
        const res = handleNetworkResponse(response, null, true);
        return res;
      },
      providesTags: [CacheStoreKey.userCacheStamp],
    }),

    // read
    getUserInfo: build.query({
      queryFn: async (args: { id: number }) => {
        if (args.id === -1) {
          return {
            data: null
          }
        }
        // This method is used to show some other vehicle's owner info. So we don't need to cache it dynamically. Just cache it for std values.
        const response = await axios.get<User>(`/user/userInfo/${args.id}`);
        
        return handleNetworkResponse(response, null);
      },
      providesTags: [CacheStoreKey.userCacheStamp],
    }),

    // read
    allUsers: build.query({
      queryFn: async (args: Pagination) => {
        // This is admin api call and no need of custome caching
        const response = await axios.get(`/user/all?offset=${args.offset}&limit=${args.limit}`);
        return handleNetworkResponse(response, null);
      },
      providesTags: [CacheStoreKey.userCacheStamp],
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useSignInMutation,
  useCreateUserMutation,
  useLogoutMutation,
  useAllUsersQuery,
  useGetLoggedInUserQuery,
  useLazyGetLoggedInUserQuery,
  useSendResetMutation,
  useVerifyResetMutation,
  useChangePasswordMutation,
  useSocialSignInMutation,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery
} = userApi;

export type UserApiType = typeof userApi;