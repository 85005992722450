import { OwnerTypes } from "../enum/ownerTypes.enum";
import { useUser } from "./useUser";
import { useSearchPartsQuery } from "../state/api/parts.api";
import { Part } from "../types/Part";

export const useOwnParts = () => {
  const { user } = useUser();
  const { data } = useSearchPartsQuery({
    limit: undefined,
    offset: 0,
    keywords: '',
    filter: {
      UserId: user.id
    },
    ownerType: OwnerTypes.My
  });
  const ownParts = (data ?? []) as Part[];

  const isOwnPart = (pId: number | string | undefined) => {
    return ownParts.find(v => v.id === Number(pId)) !== null;
  }
  return { ownParts, isOwnPart };
};
