import { appendQueryParam } from "./helper";
import { CacheStoreKey } from "../enum/cacheStore.enum";
import { StorageService } from "../services/storage.service";

export const appendCacheStamp = (
  url: string,
  storeKey: CacheStoreKey
) => {
  const cacheStamp = getCacheStamp(storeKey);
  return appendQueryParam(url, "stamp", cacheStamp) ?? url;
};

export const resetCacheStamp = (storeKey: CacheStoreKey, val?: string) => {
  const value = val ?? new Date().getTime().toString();
  StorageService.setItem(storeKey, value);
  console.log(storeKey, value);
  return value;
};

export const resetAllCacheStamps = () => {
  console.log('Reset full cache');
  Object.values(CacheStoreKey).forEach(val => {
    resetCacheStamp(val);
  })
};

export const getCacheStamp = (storeKey: CacheStoreKey): string => {
  let value = StorageService.getItem(storeKey);

  if (!value) {
    const today = new Date();
    value = today.getTime().toString();
    StorageService.setItem(storeKey, value);
  }
  return value;
};

// export const clearAllCookies = () => {
//   const cookies = document.cookie.split("; ");
//   for (let c = 0; c < cookies.length; c++) {
//     const d = window.location.hostname.split(".");
//     while (d.length > 0) {
//       const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
//       const p = location.pathname.split('/');
//       document.cookie = cookieBase + '/';
//       while (p.length > 0) {
//         document.cookie = cookieBase + p.join('/');
//         p.pop();
//       };
//       d.shift();
//     }
//   }
// };
